/* show listing */
.image-container img {
    max-width: 100%;
}

.card-container .card-img,
.card-img-top {
    border-radius: 10px;
}

.link {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
}

/* listing detail page */
.detail-image-container img {
    width: 100%;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
}

/* inquiry form */
.inquiry-form .form-group {
    margin-bottom: 20px;
}

.inquiry-form .form-group input {
    box-shadow: none;
}

.inquiry-form .form-group label {
    color: #ffffff;
}

.modal-content {
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content .btn-close {
    background-color: #ffffff;
}

/* create listing form */
.listing-form-container {
    background-color: #eee;
    padding: 20px 20px;
    border-radius: 25px;
}

.listing-form-container button {
    margin-left: auto;
    margin-right: auto;
}

.listing-form-container h3 {
    color: #7F3F98;
}

.create-listing-form .form-group {
    margin-bottom: 20px;
}

.create-listing-form .form-group input,
.create-listing-form .form-group textarea,
.create-listing-form .form-group select {
    box-shadow: none;
}

.create-listing-form .form-group label {
    margin-bottom: 5px;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
    .card-container {
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-container .card-btn {
        font-size: 11px;
    }

    .listing-form-container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .listing-form-container h3 {
        font-size: 18px;
    }

    .listing-form-container button {
        width: 90%;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) {
    .card-container .card {
        flex-direction: row;
    }

    .business-image {
        width: 180px;
        height: 180px;
        object-fit: cover;
    }

    .card-container {
        margin-left: 20px;
        margin-right: 50px;
    }

    .listing-form-container {
        margin-left: 10%;
        margin-right: 10%;
    }

    .listing-form-container h3 {
        font-size: 25px;
    }

    .listing-form-container button {
        width: 25%;
    }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) {
    .listing-form-container {
        margin-left: 15%;
        margin-right: 15%;
    }
}