.register-container,
.otp-container,
.reset-password-container,
.login-container {
    background: #eee;
    padding: 20px 20px;
    border-radius: 25px;
}

.register-container h3 {
    color: #7F3F98;
}

.register-container .form-group,
.otp-container .form-group,
.reset-password-container .form-group,
.login-container .form-group {
    margin-bottom: 20px;
}

.register-container .form-group label,
.otp-container .form-group label,
.reset-password-container .form-group label,
.login-container .form-group label {
    margin-bottom: 5px;
}

.register-container .form-group input,
.otp-container .form-group input,
.reset-password-container .form-group input,
.login-container .form-group input {
    box-shadow: none;
}

.register-container button,
.otp-container button,
.reset-password-container button,
.login-container button {
    margin-left: auto;
    margin-right: auto;
}
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
    .listing-form-container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .register-container h3 {
        font-size: 18px;
    }

    .register-container button,
    .otp-container button,
    .login-container button {
        width: 90%;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) {

    .register-container,
    .otp-container,
    .reset-password-container,
    .login-container {
        margin-left: 10%;
        margin-right: 10%;
    }

    .register-container h3 {
        font-size: 25px;
    }

    .register-container button,
    .otp-container button,
    .login-container button {
        width: 25%;
    }
} 

/* Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) {

    .register-container,
    .otp-container,
    .reset-password-container,
    .login-container {
        margin-left: 15%;
        margin-right: 15%;
    }
}