.carousel-caption {
    bottom: 35% !important;
}

.carousel-item {
    /* height: 500px; */
}

.static-img,
.carousel-item img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 400px;
}

.carousel-control-next,
.carousel-control-prev {
    filter: invert(1);
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
    .navbar-collapse {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }
    .carousel-item img {
        height: auto;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) {}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) {}