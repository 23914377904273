:root {
    --colour-one: #7F3F98;
    --colour-two: #BE1E2D;
}

/* .category-box .img-fluid {
    height: 100px;
    object-fit: contain;
} */

.categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 30px;
}

.category-box.card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    width: 160px;
    height: 160px;
    padding: 15px;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 20px;
}

.category-box.card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.category-icon {}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 374px) {
    .category-box.card {
        width: 130px;
        height: 130px;
        padding: 20px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) {
    .categories {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {
    .categories {
        grid-template-columns: repeat(5, 1fr);
    }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) {
    .categories {
        grid-template-columns: repeat(7, 1fr);
    }
}