.footer-container {
    background-color: #1c1c1c;
}

.main-footer-container {
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
}

.footer-contact {
    display: flex;
    flex-direction: column;
}

.social-icon {
    margin-right: 10px;
    color: #ffffff;
    font-size: 25px;
}

.footer-contact span,
.footer-contact a {
    color: #ffffff;
    margin-bottom: 5px;
    text-decoration: none;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
    .main-footer-container {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 25px;
        padding-right: 25px;
    }

    .footer-contact {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .footer-icon {
        border-bottom: 1px solid #cccccc;
        margin-bottom: 10px;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) {}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) {}