.brand img {
    width: 80px;
}

.nav-link {
    color: #000000;
    background-color: #FBFBFB;
    border-radius: 5px;
    padding: 2px 5px;
    margin-right: 20px;
    cursor: pointer;
}

.nav-link:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000000;
}

.text-type {
    font-size: 10px;
}

.blink-class {
    animation: blink 2s linear infinite;
    background: red;
    padding: 5px 10px;
    border-radius: 5px;
}

.blink-class a {
    text-decoration: none;
    color: #ffffff;
}

@keyframes blink {
    25% {
        opacity: 0.5;
        background: blue;
    }

    50% {
        opacity: 0;
        background: red;
    }

    75% {
        opacity: 0.5;
        background: blue;
    }
}

.blink-class:hover {
    animation: none;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
    .brand img {
        width: 60px;
    }

    .navbar-collapse {
        margin-top: 10px;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }

    .nav-link {
        background: none;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 768px) {}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 1200px) {}